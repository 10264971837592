import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const jobRoutes: Route[] = [
  {
    name: "Search Jobs",
    path: `${basePath}/jobs`,
    qsParams: [
      {
        name: "completed",
        label: "Completed",
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
      },
      {
        name: "has_device_install_tasks",
        label: "Has Device Install Task(s)",
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
      },
      { name: "name" },
      { name: "company_id" },
      { name: "project_id" },
      { name: "property_id" },
      { name: "unit_id" },
      { name: "unit_remote_id" },
      { name: "contains_date", type: "date", required: false },
      { name: "start_date", type: "date" },
      { name: "end_date", type: "date" },
      { name: "starts_in_days", type: "number" },
      {
        name: "status",
        options: [
          { label: "Opt out", value: "opt-out" },
          { label: "Cannot complete", value: "cannot-complete" },
          { label: "Completed", value: "completed" },
          { label: "Qa ready", value: "qa-ready" },
          { label: "Rescheduled", value: "rescheduled" },
          { label: "Pending", value: "pending" },
          { label: "Overdue", value: "overdue" },
          { label: "Current", value: "current" },
        ],
      },
      { name: "assigned_to_user_ids", json: true, defaultValue: "[]" },
      {
        name: "flagged",
        type: "boolean",
      },
    ],
  },
  {
    name: "Fetch a Job",
    path: `${basePath}/jobs/:job_id`,
  },
  {
    name: "Create a Job",
    path: `${basePath}/jobs`,
    method: "POST",
    documentation: `
    # Create a job

    Current install_type, device_types, and reasons options can be found from "/api/v1/install-details/options"
    `,
    body: [
      { name: "name", required: true },
      { name: "start_date", type: "date", required: true },
      { name: "end_date", type: "date", required: true },
      { name: "job_template_id" },
      { name: "project_id" },
      {
        name: "unit_remote_ids",
        type: "textarea",
        json: true,
        defaultValue: "[]",
      },
      { name: "expected_unit_count", type: "number" },
      { name: "property_remote_id" },
      { name: "company_id", required: true },
      {
        name: "unit_search_filters",
        label: "Unit Search Filters",
        properties: [
          { name: "property_remote_id" },
          {
            name: "excluded_unit_remote_ids",
            type: "textarea",
            json: true,
            defaultValue: "[]",
          },
        ],
      },
      {
        name: "install_type",
      },
      {
        name: "device_types",
        json: true,
        defaultValue: "[]",
      },
      {
        name: "reasons",
        json: true,
        defaultValue: "[]",
      },
    ],
  },
  {
    name: "Create Jobs Async",
    path: `${basePath}/jobs/create-async`,
    method: "POST",
    documentation: `
    # Create jobs async

    Current install_type, device_types, and reasons options can be found from "/api/v1/install-details/options"
    `,
    body: [
      { name: "name", required: true },
      { name: "start_date", type: "date", required: true },
      { name: "end_date", type: "date", required: true },
      { name: "job_template_id" },
      { name: "project_id" },
      {
        name: "unit_remote_ids",
        type: "textarea",
        json: true,
        defaultValue: "[]",
      },
      { name: "expected_unit_count", type: "number" },
      { name: "property_remote_id" },
      { name: "company_id", required: true },
      {
        name: "unit_search_filters",
        label: "Unit Search Filters",
        properties: [
          { name: "property_remote_id" },
          {
            name: "excluded_unit_remote_ids",
            type: "textarea",
            json: true,
            defaultValue: "[]",
          },
        ],
      },
      {
        name: "install_type",
      },
      {
        name: "device_types",
        json: true,
        defaultValue: "[]",
      },
      {
        name: "reasons",
        json: true,
        defaultValue: "[]",
      },
    ],
  },
  {
    name: "Update a Job",
    path: `${basePath}/jobs/:job_id`,
    method: "PATCH",
    body: [
      { name: "name" },
      { name: "start_date", type: "date" },
      { name: "end_date", type: "date" },
    ],
  },
  {
    name: "Delete a Job",
    path: `${basePath}/jobs/:job_id`,
    method: "DELETE",
  },
  {
    name: "Get Deleted Jobs",
    path: `${basePath}/jobs/deleted`,
    qsParams: [{ name: "page" }],
  },
  {
    name: "Restore a Batch Of Deleted Jobs",
    path: `${basePath}/jobs/:batch_id/restore`,
    method: "PUT",
  },
  {
    name: "Mark a Job as Ready for QA",
    path: `${basePath}/jobs/:job_id/qa-ready`,
    method: "PATCH",
  },
  {
    name: "Mark a Job as Not Ready for QA",
    path: `${basePath}/jobs/:job_id/remove-qa-ready`,
    method: "PATCH",
  },
  {
    name: "Mark a Job as Completed",
    path: `${basePath}/jobs/:job_id/complete`,
    method: "POST",
  },
  {
    name: "Mark a Job as Not Completed",
    path: `${basePath}/jobs/:job_id/uncomplete`,
    method: "POST",
  },
  {
    name: "Declare that a Job Cannot Be Completed",
    path: `${basePath}/jobs/:job_id/cannot-complete`,
    method: "POST",
    body: [{ name: "reason", type: "string" }],
  },
  {
    name: "Declare that a tenant opted out of an install",
    path: `${basePath}/jobs/:job_id/opt-out`,
    method: "PATCH",
  },
  {
    name: "Mark a job as rescheduled",
    path: `${basePath}/jobs/:job_id/reschedule`,
    method: "PATCH",
  },
  {
    name: "Bulk Assign Users to Jobs",
    path: `${basePath}/jobs-users`,
    method: "POST",
    body: [
      { name: "project_id", required: true },
      {
        name: "job_ids",
        type: "textarea",
        required: true,
        json: true,
        defaultValue: "[]",
      },
      {
        name: "user_ids",
        type: "textarea",
        json: true,
        defaultValue: "[]",
      },
      {
        name: "roles",
        type: "textarea",
        json: true,
        defaultValue: "[]",
      },
    ],
  },
  {
    name: "Set Users Assigned to Job",
    path: `${basePath}/jobs/:job_id/users`,
    method: "PUT",
    body: [
      {
        name: "user_ids",
        type: "textarea",
        json: true,
        defaultValue: "[]",
      },
    ],
  },
  {
    name: "Unassign User from Job",
    path: `${basePath}/jobs/:job_id/users/:user_id`,
    method: "DELETE",
  },
  {
    name: "Get unique inserted dates",
    path: `${basePath}/jobs/unique_inserted_dates`,
    qsParams: [
      {
        name: "project_id",
      },
    ],
  },
  {
    name: "Get low battery alerts for a job",
    path: `${basePath}/jobs/:job_id/battery-alerts`,
  },
];

export default jobRoutes;
