import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const taskRoutes: Route[] = [
  {
    name: "Fetch Tasks in Job",
    path: `${basePath}/jobs/:job_id/tasks`,
  },
  {
    name: "Fetch a Task",
    path: `${basePath}/tasks/:task_id`,
  },
  {
    name: "Create a Task",
    path: `${basePath}/tasks`,
    method: "POST",
    body: [
      { name: "name", required: true },
      { name: "job_id", required: true },
      { name: "description" },
      { name: "question", required: false },
      {
        name: "type",
        options: [
          { label: "Checkbox", value: "checkbox" },
          { label: "Image Upload", value: "image-upload" },
          { label: "Device Install", value: "device-install" },
          { label: "Move In Residents", value: "move-in-residents" },
          { label: "Ring Install", value: "ring-install" },
          {
            label: "Internal Device Install",
            value: "internal-device-install",
          },
          {
            label: "Management Notes",
            value: "management-notes",
          },
        ],
        required: true,
      },
      {
        name: "meta",
        properties: [
          {
            name: "device_category",
            label: "Device Category",
            options: [
              { value: "entry_control", label: "Door Lock" },
              { value: "switch_binary", label: "Smart Plug" },
              { value: "switch_multilevel", label: "Dimmer Switch" },
              { value: "shade_control", label: "Shade Control" },
              { value: "thermostat", label: "Thermostat" },
              { value: "leak_sensor", label: "Leak Sensor" },
              { value: "contact_sensor", label: "Contact Sensor" },
              { value: "motion_sensor", label: "Motion Sensor" },
              { value: "siren", label: "Siren" },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Update a Task",
    path: `${basePath}/tasks/:task_id`,
    method: "PATCH",
    body: [{ name: "name" }, { name: "description" }],
  },
  {
    name: "Delete a Task",
    path: `${basePath}/tasks/:task_id`,
    method: "DELETE",
  },
  {
    name: "Update Order of Tasks within a Job",
    path: `${basePath}/jobs/:job_id/tasks/order`,
    method: "POST",
    body: [
      {
        name: "order",
        type: "textarea",
        json: true,
        defaultValue: "[]",
      },
    ],
  },
  {
    name: "Set Users Assigned to Task",
    path: `${basePath}/tasks/:task_id/users`,
    method: "PUT",
    body: [
      {
        name: "user_ids",
        type: "textarea",
        json: true,
        defaultValue: "[]",
      },
    ],
  },
  {
    name: "Unassign User from Task",
    path: `${basePath}/tasks/:task_id/users/:user_id`,
    method: "DELETE",
  },
  {
    name: "Cannot Complete Task",
    path: `${basePath}/tasks/:task_id/cannot-complete`,
    method: "POST",
    body: [{ name: "reason", type: "string" }],
  },
  {
    name: "Cannot Complete Task Suggestion",
    path: `${basePath}/cannot-complete-suggestions`,
    qsParams: [
      {
        name: "page",
        label: "page",
      },
      {
        name: "type",
        label: "type",
      },
    ],
  },
  {
    name: "[Device Install] Get Devices Matching Task Type",
    path: `${basePath}/tasks/:task_id/device-install/devices`,
  },
  {
    name: "[Device Install] Assign Device to Task",
    path: `${basePath}/tasks/:task_id/device-install/devices/assign`,
    method: "POST",
    body: [{ name: "device_id", type: "number" }],
  },
  {
    name: "[Device Install] Get installation progress for device",
    path: `${basePath}/tasks/:task_id/device-install/progress`,
  },
  {
    name: "Fetch all leases",
    path: `${basePath}/tasks/:task_id/move-in-residents/leases`,
  },
  {
    name: "Move Ins - Unit Precheck",
    path: `${basePath}/tasks/:task_id/move-in-residents/precheck`,
  },
  {
    name: "Resident Workflows",
    path: "${/tasks/:task_id/move-in-residents/residents/:resident_id/workflows",
  },
  {
    name: "Current Resident Workflow",
    path: "/tasks/:task_id/move-in-residents/residents/:resident_id/current-workflow",
  },
  {
    name: "Installer Code",
    path: `${basePath}/tasks/:task_id/device-install/installer-code`,
    qsParams: [
      {
        name: "user_name",
        type: "string",
      },
    ],
  },
  {
    name: "Change a device's attribute states",
    path: `${basePath}/tasks/:task_id/device-install/attribute-states`,
    method: "PATCH",
    body: [
      {
        name: "attributes",
        array: true,
        properties: [
          { name: "battery_level" },
          { name: "connected" },
          { name: "user_codes" },
          { name: "lock_schedule" },
          { name: "motion_binary" },
          { name: "leak" },
          { name: "contact_broken" },
          { name: "on" },
          { name: "level" },
          { name: "level" },
          { name: "locked" },
          { name: "notifications" },
          { name: "open" },
          { name: "siren_active" },
          { name: "tamper" },
          { name: "valve_state" },
          { name: "user_codes" },
          { name: "mode" },
          { name: "fan_mode" },
          { name: "operating_state" },
          { name: "current_temp" },
          { name: "heating_setpoint" },
          { name: "cooling_setpoint" },
          { name: "current_humidity" },
        ],
      },
    ],
    documentation:
      "https://smartrent.atlassian.net/wiki/spaces/ENG/pages/855343190/Devices",
  },
  {
    name: "Create Ring Mac Address",
    path: `${basePath}/units/:unit_id/tasks/:task_id/ring-install/mac-address`,
    method: "POST",
    body: [
      { name: "mac_address", type: "string" },
      { name: "url", type: "string" },
    ],
  },
  {
    name: "Delete Ring Mac Address",
    path: `${basePath}/tasks/:task_id/ring-install/mac-address`,
    method: "DELETE",
  },
  {
    name: "Complete Ring Install",
    path: `${basePath}/tasks/:task_id/ring-install`,
    method: "POST",
  },
  {
    name: "Complete Management Notes Task",
    path: `${basePath}/tasks/:task_id/management-notes`,
    method: "POST",
    body: [{ name: "note", type: "string" }],
  },
  {
    name: "Fetch Internal Thermostat Templates",
    path: `${basePath}/tasks/:task_id/internal-thermostat-templates`,
    deprecated: true,
  },
  {
    name: "Create Internal Thermostat",
    deprecated: true,
    path: `${basePath}/units/:unit_id/tasks/:task_id/internal-thermostats`,
    method: "POST",
    body: [
      {
        name: "hub_id",
        type: "string",
      },
      {
        name: "configuration",
        type: "string",
      },
      {
        name: "device_name",
        type: "string",
      },
      {
        name: "scale",
        type: "string",
      },
    ],
  },
  {
    name: "Delete images on image-upload task",
    path: `${basePath}/tasks/:task_id/image-upload`,
    method: "DELETE",
  },
  {
    name: "Search task flags",
    path: `${basePath}/task-flags`,
    qsParams: [
      { name: "task_id" },
      { name: "job_id" },
      { name: "project_id" },
      { name: "active", type: "boolean" },
    ],
  },
];

export default taskRoutes;
